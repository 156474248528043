<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh" class="hidden-sm-only hidden-sm-only">刷新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="危急值监控管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="值班医生">
                    <el-input v-model="search.account_name" placeholder="请输入值班医生" clearable/>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="search.phoneNumber" placeholder="请输入电话称" clearable/>
                </el-form-item>
                <el-form-item label="处理时间">
                    <el-date-picker v-model="search.dispose_time" type="daterange" range-separator="至" clearable
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search"
                               v-has="'weijizhi_search'">查询
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border
                          >
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.patientName }}</span>
                                <span v-if="scope.row.gender == '2'"> 女 </span>
                                <span v-if="scope.row.gender == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div><small> {{ maskIdCardRegex(scope.row.idCard || "") }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                   @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phoneNumber" label="手机号" width="" show-overflow-tooltip/>
                    <el-table-column prop="healthHutName" label="小屋名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="deviceName" label="设备名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="crisisValueLevel" label="危急级别" width="" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.crisisValueLevel == '3'">三级</el-tag>
                            <el-tag type="warning" v-if="scope.row.crisisValueLevel == '2'">二级</el-tag>
                            <el-tag type="info" v-if="scope.row.crisisValueLevel == '1'">一级</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reportStatus" label="处理状态" align="center" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.reportStatus == '0'">未处理</el-tag>
                            <el-tag type="success" v-if="scope.row.reportStatus == '1'">已处理</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="itemName" label="测量项目" width="" show-overflow-tooltip/>
                    <el-table-column prop="itemValue" label="实际值" width="" show-overflow-tooltip/>
                    <el-table-column prop="reportTime" label="报告时间" width="" show-overflow-tooltip/>
                    <el-table-column prop="dispose_time" label="处理时间" width="" show-overflow-tooltip >
                        <template slot-scope="scope">
                            <span>{{scope.row.dispose_time | datetime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dispose" label="处理过程" width="" show-overflow-tooltip >
                    </el-table-column>
                    <el-table-column prop="account_name" label="处理医生" width="" show-overflow-tooltip />
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'workload',
        data() {
            return {
                loading: false,
                search: {dispose_time: []},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                form: {},
                isFullIdCard: [],
            }
        },
        methods: {
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    this.$http.get(`/hut/weijizhi?page=${this.table.page}&pageSize=${this.table.pageSize}&reportStatus=1&crisisValueLevel=3`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
